/*eslint-disable*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useLocation } from "react-router-dom";
// core components
import Header from "../../components/Header/Header.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
// sections for this page
import SectionDescription from "./Sections/SectionDescription";
import SectionComponents from "./Sections/SectionComponents.js";
import SectionCards from "./Sections/SectionCards.js";

import presentationStyle from "../../assets/jss/material-kit-pro-react/views/presentationStyle";

import care from "../../assets/img/samsung/heart_black.png";
import bg4 from "../../assets/img/bg4.jpg";
import allAlpha from "../../assets/img/Horizontal_Banner_White_trans.png";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import { CookieConsent } from "react-cookie-consent";

const useStyles = makeStyles(presentationStyle);
const siteData = window.samsung;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PresentationPage() {
  let query = useQuery();
  //https://dev.samsungprotect.gr/?txId=123&orderid=orderid123&mid=mid&status=status&orderAmount=120&currency=euro&paymentTotal=120&message=message&payMethod=payMethod&paymentRef=paymentRef&digest=digest&contractno=contest123
  const [payment, setPayment] = useState({});
  const [paymentMsg, setPaymentMsg] = useState("");
  console.log(query.get("contractno"));
  console.log(query.get("value"));
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (query.get("contractno")) {
      setPayment({ contractΝumber: query.get("contractno") });
    } else if (window.location.pathname.includes("/fail")) {
      setPayment(siteData);
      setPaymentMsg(` ${siteData?.status}: Η ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΠΡΑΓΜΑΤΟΠΟΙΗΘΗΚΕ`);
    }
    // alert(siteData.status);
  }, []);

  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Ασφάλιση Συσκευών"
        links={<HeaderLinks dropdownHoverColor="info" />}
        appbar
        color="white"
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <GridContainer justify="center">
        <GridItem md={9} lg={9} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5
              className={classes.footerBrand}
              style={{ padding: 0, margin: 0, height: 30 }}
            >
              {paymentMsg}
            </h5>
          </div>
        </GridItem>
        <GridItem md={9} lg={9} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {payment?.contractΝumber && (
              <h5 className={classes.footerBrand}>
                Η πληρωμή έγινε επιτυχώς. Ο αριθμός του συμβολαίου σας είναι:{" "}
                <span style={{ color: "#000" }}>
                  {" "}
                  {payment?.contractΝumber}
                </span>
              </h5>
            )}
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem md={9} lg={9} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: 60,
                height: 50,
                marginTop: 40,
                marginBottom: 20,
              }}
              src={care}
              alt="care-icon"
            />
          </div>
          <div className={classes.brand}>
            <h2 className={classes.title}>Samsung Protect</h2>
            <h5 style={{ color: "#000" }}>
              Στη ζωή συμβαίνουν πολλά απρόβλεπτα, με την Ασφάλεια συσκευών
              Samsung με την εγγύηση της Allianz Partners όμως είσαι καλυμμένος.
            </h5>
          </div>
          <Card plain blog className={classes.card4}>
            <CardHeader image plain>
              <a href="#" onClick={(e) => e.preventDefault()}>
                <img src={bg4} alt="girl" />
              </a>
              <div
                className={classes.coloredShadow}
                style={{ backgroundImage: `url(${bg4})`, opacity: "1" }}
              />
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionComponents />
        <SectionDescription />

        <SectionCards />
      </div>

      <Footer
        theme="white"
        content={
          <div className={classes.flexCenter}>
            <div className={classes.left}>
              <a
                href="https://samsungcareplus.gr/"
                target="_blank"
                className={classes.footerBrand}
              >
                Samsung – Allianz Partners
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://bol.mondial-assistance.gr/terms_conditions/Samsung_Care_Plus_White_Label_Wbsite_Legal_Information.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    Όροι Χρήσης
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://bol.mondial-assistance.gr/terms_conditions/Samsung_Care_Plus_%20White%20Label%20website_Privacy_Policy&Cookies.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    Πολιτική Απορρήτου
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://bol.mondial-assistance.gr/terms_conditions/Complaints_Procedure.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    Διαδικασία Υποβολής Παραπόνων
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://bol.mondial-assistance.gr/terms_conditions/Complains_form.doc"
                    target="_blank"
                    className={classes.block}
                  >
                    Φόρμα Υποβολής Παραπόνων
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <a href="http://www.alpha.gr/e-Commerce" target="_blank">
                    <img src={allAlpha} alt="american-express" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        }
      />
      <CookieConsent
        location="bottom"
        buttonText="Αποδοχή"
        cookieName="cookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "14px",
          fontWeight: "bold",
          position: "relative",
          top: 25,
          cursor: "pointer",
        }}
        expires={150}
      >
        <span>
          Η Allianz Partners χρησιμοποιεί cookies προκειμένου να καταστήσει τον
          παρόντα διαδικτυακό τόπο περισσότερο λειτουργικό, ασφαλή και εύκολο
          στη χρήση, έχοντας ως κύριο σκοπό τη βελτίωση της online εμπειρίας
          σας.
        </span>
        <br />
        <span>
          Εφόσον επιλέξετε να συνεχίσετε την περιήγησή σας στον παρόντα
          διαδικτυακό τόπο, αποδέχεσθε τουλάχιστον τη χρήση των cookies που
          είναι απαραίτητα για τη λειτουργικότητα του εν λόγω διαδικτυακού
          τόπου.
        </span>
        <br />
        <span>
          Για περισσότερες πληροφορίες σχετικά με τα cookies, παρακαλούμε όπως
          ανατρέξετε στην{" "}
          <a
            href={
              "https://bol.mondial-assistance.gr/terms_conditions/Samsung_Care_Plus_%20White%20Label%20website_Privacy_Policy&Cookies.pdf"
            }
            target={"_blank"}
          >
            Πολιτική Απορρήτου
          </a>{" "}
          που παρατίθεται στο κάτω μέρος της παρούσας σελίδας.
        </span>
      </CookieConsent>
    </div>
  );
}
