export const findUnique = (data, field) => {
  let res = [];
  if (data?.length) {
    res = [
      ...data
        ?.reduce((a, c) => {
          a.set(c[field], c);
          return a;
        }, new Map())
        .values(),
    ];
  }

  return res.filter((el) => el[field] !== null);
};

export const validate = (inputs, isImei) => {
  const errors = {};

  if (!inputs?.firstName || inputs?.firstName?.length < 3) {
    errors.firstName = true;
  }
  if (!inputs?.lastName || inputs?.lastName?.length < 3) {
    errors.lastName = true;
  }
  if (!inputs?.address || inputs?.address?.length < 3) {
    errors.address = true;
  }

  if (!inputs?.zipcode || inputs?.zipcode?.length < 5) {
    errors.zipcode = true;
  }
  if (!inputs?.city || inputs?.city?.length < 3) {
    errors.city = true;
  }
  if (
    !inputs?.email ||
    inputs?.email?.length < 3 ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
  ) {
    errors.email = true;
  }
  if (
    !inputs?.phone ||
    inputs?.phone?.length < 10 ||
    !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
      inputs.phone
    )
  ) {
    errors.phone = true;
  }
  if (!inputs?.serialNumber || inputs?.serialNumber?.length > 15) {
    errors.serialNumber = true;
  }
  // check if serial number has 1 or more letters
  if (!isImei) {
    if (
      !inputs?.serialNumber ||
      !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(inputs?.serialNumber)
    ) {
      errors.serialNumber = true;
    }
  }
  if (!inputs?.gadgetCode || inputs?.gadgetCode?.length < 3) {
    errors.gadgetCode = true;
  }

  // if (!inputs?.cover || inputs?.cover?.length < 3) {
  //   errors.cover = true;
  // }
  // if (!inputs?.purchaseDate) {
  //   errors.purchaseDate = true;
  // }
  console.log(errors);
  return errors;
};
