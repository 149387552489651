/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import care from "../../assets/img/samsung/heart_black.png";
import { Info } from "@material-ui/icons";
import Small from "../../components/Typography/Small";
import { useAppContext } from "../../config/AppProvider";
import { validate } from "../../config/helpers";
import { apiUrl } from "../../config/config";
import CustomizedSnackbars from "../../components/Snackbar/Snack";

const useStyles = makeStyles(signupPageStyle);

export default function FindProgram3({ handleNext, handleBack }) {
  const {
    cart,
    orderInputs,
    handleOrderInputsChange,
    handlePayment,
    callApi,
    snackNotify,
    setSnackOpen,
  } = useAppContext();
  const [errors, setErrors] = useState({});
  const [models, setModels] = useState([]);

  useEffect(() => {
    getModels();
  }, []);

  const getModels = async () => {
    const res = await callApi(`${apiUrl}/v1/models`, "POST", {
      categoryID: orderInputs.productGroup,
    });

    if (res?.length) {
      setModels(res);
    }
  };

  const classes = useStyles();
  const title1 =
    "ο αριθμός ΙΜΕΙ σε συσκευή κινητού τηλεφώνου αποτελείται από 15 ψηφία (χωρίς γράμματα)";
  const title2 =
    "οι σειριακοι αριθμοί αποτελούνται από 15 χαρακτήρες (αριθμοί και γράμματα)\n" +
    " ";

  const handleNextPage = async () => {
    const validateItems = {
      firstName: orderInputs.firstName,
      lastName: orderInputs.lastName,
      address: orderInputs.address,
      zipcode: orderInputs.zipcode,
      city: orderInputs.city,
      email: orderInputs.email,
      phone: orderInputs.phone,
      serialNumber: orderInputs.serialNumber,
      invoiceNumber: orderInputs.invoiceNumber,
      gadgetCode: orderInputs.gadgetCode,
      salesPoint: orderInputs.salesPoint,
    };

    const valid = validate(
      validateItems,
      orderInputs?.productGroup === "500001" // check if is imei of serial
    );
    setErrors(valid);

    if (
      !orderInputs.agree ||
      !orderInputs.agree2 ||
      Object.entries(valid).length !== 0
    )
      return;
    if (orderInputs.salesPoint === 0) {
      snackNotify(401, "Επιλέξτε σημείο αγοράς προϊόντος!");
      setSnackOpen(true);
      return;
    }
    handlePayment();
    handleNext();
  };
  console.log(orderInputs?.salesPoint);
  return (
    <div className={classes.container}>
      <CustomizedSnackbars />
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={3} md={3}>
                  <InfoArea
                    className={classes.infoArea}
                    title={`Τιμή ${cart?.grossPrice}€`}
                    description={
                      <div>
                        <ul
                          style={{
                            padding: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <li style={{ marginBottom: 5 }}>
                            (συμπεριλαμβανομένων των αναλογούντων φόρων και του
                            δικαιώματος για την έκδοση του ασφαλιστηρίου
                            συμβολαίου)
                          </li>
                          <li style={{ listStyle: "none" }}>
                            {cart?.insuranceProduct}
                          </li>
                          {cart?.addOnProduct && (
                            <span style={{ fontSize: 22 }}>+</span>
                          )}
                          <li style={{ listStyle: "none" }}>
                            {cart?.addOnProduct && cart?.addOnProduct}
                          </li>
                        </ul>
                      </div>
                    }
                    // description={`<div>${
                    //   cart?.insuranceProduct
                    // }</div> +  <div>${
                    //   cart?.addOnProduct && cart?.addOnProduct
                    // }</div>`}
                    icon={care}
                    width={40}
                  />
                </GridItem>
                <GridItem xs={12} sm={9} md={9}>
                  <form className={classes.form}>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.serialNumber}
                          id="imei"
                          labelText={
                            orderInputs?.productGroup === "500001"
                              ? "Σειριακός Αριθμός Προϊόντος (IMEI)"
                              : "Σειριακός Αριθμός Προϊόντος (SN)"
                          }
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    orderInputs?.productGroup === "500001"
                                      ? title1
                                      : title2
                                  }
                                  aria-label="info"
                                >
                                  <Info />
                                </Tooltip>
                              </InputAdornment>
                            ),

                            name: "serialNumber",
                            type:
                              orderInputs?.productGroup === "500001"
                                ? "number"
                                : "default",
                            value: orderInputs.serialNumber,
                            placeholder: "ex: 1234567890",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "serialNumber",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6}>
                        <Autocomplete
                          freeSolo
                          error={"y"}
                          id="free-solo-2-demo"
                          disableClearable
                          options={models.map(
                            (option) =>
                              `${option.gadgetCode} | ${option.gadgetDescription}`
                          )}
                          onChange={(event, newValue) => {
                            let v = newValue.split("|");
                            handleOrderInputsChange("gadgetCode", v[0].trim());
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={errors?.gadgetCode}
                              style={{ marginTop: 10 }}
                              {...params}
                              label="Αναζήτηση Μοντέλου"
                              // margin="normal"
                            />
                          )}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      style={{
                        marginBottom: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.invoiceNumber}
                          id="invoiceNumber"
                          labelText="Αριθµός Παραστατικού"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "parastatiko",
                            type: "default",
                            value: orderInputs?.invoiceNumber,
                            placeholder: "Αριθµός Παραστατικού",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "invoiceNumber",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Autocomplete
                          style={{ marginTop: -15 }}
                          defaultValue={"Αγορά μέσω Samsung.com/gr"}
                          freeSolo
                          error={"y"}
                          id="free-solo-2-demo"
                          disableClearable
                          options={[
                            { name: "Αγορά μέσω Samsung.com/gr", value: 1 },
                            {
                              name: "Αγορά μέσω συνεργάτη 4271146",
                              value: 3,
                            },
                            {
                              name: "Αγορά μέσω συνεργάτη 1411640",
                              value: 4,
                            },
                            { name: "Αγορά μέσω συνεργάτη 1978470", value: 5 },
                          ].map((option) => option?.name)}
                          onChange={(e, value) => {
                            console.log(value);
                            handleOrderInputsChange(
                              "salesPoint",
                              [
                                { name: "Αγορά μέσω Samsung.com/gr", value: 1 },
                                {
                                  name: "Αγορά μέσω συνεργάτη 4271146",
                                  value: 3,
                                },
                                {
                                  name: "Αγορά μέσω συνεργάτη 1411640",
                                  value: 4,
                                },
                                {
                                  name: "Αγορά μέσω συνεργάτη 1978470",
                                  value: 5,
                                },
                              ]?.find((fnd) => fnd?.name === value).value
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={errors?.gadgetCode}
                              style={{ marginTop: 10 }}
                              {...params}
                              label="Επιλέξτε σημείο αγοράς ασφαλιστικού προϊόντος"
                              // margin="normal"
                            />
                          )}
                        />
                      </GridItem>
                    </GridContainer>

                    <InputLabel>Πληροφορίες Κατόχου Συσκευής</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.firstName}
                          id="firstName"
                          labelText="Όνομα"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "firstName",
                            type: "default",
                            value: orderInputs?.firstName,
                            placeholder: "Όνομα",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "firstName",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.lastName}
                          id="lastName"
                          labelText="Επίθετο"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "lastName",
                            type: "default",
                            value: orderInputs?.lastName,
                            placeholder: "Επίθετο",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "lastName",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.phone}
                          id="phone"
                          labelText="Τηλέφωνο Επικοινωνίας"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "phone",
                            type: "default",
                            value: orderInputs?.phone,
                            placeholder: "Τηλέφωνο Επικοινωνίας",
                            onChange: (e) =>
                              handleOrderInputsChange("phone", e.target.value),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.email}
                          id="email"
                          labelText="E mail"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "email",
                            type: "default",
                            value: orderInputs?.email,
                            placeholder: "E mail",
                            onChange: (e) =>
                              handleOrderInputsChange("email", e.target.value),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          error={errors?.address}
                          id="address"
                          labelText="Οδός, Αριθμός"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "address",
                            type: "default",
                            value: orderInputs?.address,
                            placeholder: "Οδός, Αριθμός",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "address",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={3}>
                        <CustomInput
                          error={errors?.city}
                          id="city"
                          labelText="Πόλη"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "city",
                            type: "default",
                            value: orderInputs?.city,
                            placeholder: "Πόλη",
                            onChange: (e) =>
                              handleOrderInputsChange("city", e.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <CustomInput
                          error={errors?.zipcode}
                          id="zipcode"
                          labelText="TK"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "zipcode",
                            type: "number",
                            value: orderInputs?.zipcode,
                            placeholder: "TK",
                            onChange: (e) =>
                              handleOrderInputsChange(
                                "zipcode",
                                e.target.value
                              ),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    {/*<GridContainer>*/}
                    {/*  <GridItem xs={12} sm={6}>*/}
                    {/*    <CustomInput*/}
                    {/*      error={errors?.city}*/}
                    {/*      id="city"*/}
                    {/*      labelText="Πόλη"*/}
                    {/*      formControlProps={{*/}
                    {/*        fullWidth: true,*/}
                    {/*      }}*/}
                    {/*      inputProps={{*/}
                    {/*        name: "city",*/}
                    {/*        type: "default",*/}
                    {/*        value: orderInputs?.city,*/}
                    {/*        placeholder: "Πόλη",*/}
                    {/*        onChange: (e) =>*/}
                    {/*          handleOrderInputsChange("city", e.target.value),*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </GridItem>*/}

                    {/*  <GridItem xs={12} sm={6}>*/}
                    {/*    <CustomInput*/}
                    {/*      error={errors?.country}*/}
                    {/*      id="country"*/}
                    {/*      labelText="Χώρα"*/}
                    {/*      formControlProps={{*/}
                    {/*        fullWidth: true,*/}
                    {/*      }}*/}
                    {/*      inputProps={{*/}
                    {/*        name: "country",*/}
                    {/*        type: "default",*/}
                    {/*        value: orderInputs?.country,*/}
                    {/*        placeholder: "Χώρα",*/}
                    {/*        onChange: (e) =>*/}
                    {/*          handleOrderInputsChange(*/}
                    {/*            "country",*/}
                    {/*            e.target.value*/}
                    {/*          ),*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </GridItem>*/}
                    {/*</GridContainer>*/}

                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <InputLabel style={{ paddingTop: 16 }}>
                          Τρόπος Πληρωμής
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Checkbox
                              checked={true}
                              disabled
                              // onChange={handleCheckChange}
                              name="checkedG"
                              color="primary"
                            />
                          }
                          label="Πιστωτική Κάρτα"
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer
                      style={{
                        marginTop: 20,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            checked={orderInputs.agree}
                            onChange={(e) =>
                              handleOrderInputsChange("agree", e.target.checked)
                            }
                            name="agree"
                            color="primary"
                          />
                        }
                        label={
                          <Small>
                            Βεβαιώνω ότι Εχω διαβάσει και αποδέχομαι το
                            <a href={cart?.ipidUrl} target="_blank">
                              {" "}
                              Προ-συμβατικό Έγγραφο Πληροφοριών
                            </a>{" "}
                            καθώς και τους{" "}
                            <a href={cart?.termsUrl} target="_blank">
                              Όρους και Προϋποθέσεις{" "}
                            </a>{" "}
                            του ασφαλιστικού προϊόντος που επέλεξα, το οποίο
                            πιστεύω ότι, καλύπτει τις ασφαλιστικές μου ανάγκες.
                            εχω διαβάσει και αποδέχομαι την{" "}
                            <a
                              href="https://bol.mondial-assistance.gr/terms_conditions/Samsung_Care_Plus_%20White%20Label%20website_Privacy_Policy&Cookies.pdf"
                              target="_blank"
                            >
                              Πολιτική Προστασίας Προσωπικών Δεδομένων
                            </a>
                          </Small>
                        }
                      />
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            checked={orderInputs.agree2}
                            onChange={(e) =>
                              handleOrderInputsChange(
                                "agree2",
                                e.target.checked
                              )
                            }
                            name="agree2"
                            color="primary"
                          />
                        }
                        label={
                          <Small>
                            Βεβαιώνω ότι η συνήθης διαμονή μου είναι στην
                            Ελλάδα.{" "}
                          </Small>
                        }
                      />
                    </GridContainer>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                justifyContent: "space-around",
              }}
              className={classes.textCenter}
            >
              <Button onClick={handleBack} round color="blackColor">
                Πίσω
              </Button>
              <Button
                disabled={!orderInputs.agree || !orderInputs?.agree2}
                onClick={handleNextPage}
                round
                color="blackColor"
              >
                Συνέχεια
              </Button>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
