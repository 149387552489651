import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

// @material-ui icons

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

import phone1 from "../../../assets/img/samsung/04_Howtobuy_Icon_003.png";
import phone2 from "../../../assets/img/samsung/03_coverage_DeviceRepair.png";
import phone3 from "../../../assets/img/samsung/03_coverage_CosmeticDamage_h222_mo.png";

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <InfoArea
                title=""
                description="Επέκταση Εγγύησης έως τα 5 έτη από την ημερομηνία αγοράς της συσκευής σας."
                icon={phone1}
                width={100}
                side
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title=""
                description="Επισκευές από εξουσιοδοτημένα επισκευαστικά κέντρα, με χρήση γνήσιων ανταλλακτικών "
                icon={phone2}
                width={100}
                side
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title=""
                description="Κάλυψη ατυχήματος"
                icon={phone3}
                width={100}
                side
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
