/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { formatISO } from "date-fns";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import care from "../../assets/img/samsung/heart_black.png";
import { useAppContext } from "../../config/AppProvider";
import Muted from "../../components/Typography/Muted";

const useStyles = makeStyles(signupPageStyle);

export default function FindProgram1({ handleNext }) {
  const {
    data,
    getApiData,
    orderInputs,
    handleOrderInputsChange,
    snackNotify,
    setSnackOpen,
  } = useAppContext();
  const [disabled, setDisabled] = useState(true);
  // const [state, setState] = useState({
  //   categoryID: "",
  //   price: "",
  //   salesdate: null,
  // });

  useEffect(() => {
    if (
      orderInputs.productGroup !== "" &&
      orderInputs.price !== "" &&
      orderInputs.purchaseDate !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [orderInputs]);

  const classes = useStyles();

  const valid = function (current) {
    return current < new Date();
  };
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={10}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={5} md={5}>
                  <InfoArea
                    className={classes.infoArea}
                    title=""
                    description="Συμπληρώστε τα παρακάτω
στοιχεία και βρείτε το
πρόγραμμα που
ανταποκρίνεται στις ανάγκες
σας"
                    icon={care}
                    width={40}
                  />
                  <Muted>
                    <span style={{ fontWeight: "bold" }}>Προσοχή</span>:
                    Ασφαλίζονται συσκευές που προορίζονται μόνο για οικιακή και
                    ΌΧΙ επαγγελματική χρήση
                  </Muted>
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                  <form className={classes.form}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Είδος Προϊόντος:
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={orderInputs.productGroup}
                        onChange={(e) =>
                          handleOrderInputsChange(
                            "productGroup",
                            e.target.value
                          )
                        }
                        inputProps={{
                          name: "categoryID",
                          id: "simple-select",
                        }}
                      >
                        {data?.categories?.map((el) => (
                          <MenuItem
                            key={el.productGroup}
                            value={el.productGroup}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            {el.groupDescription}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <CustomInput
                      id="SupplierComment"
                      labelText="Τιμή Αγοράς σε €:"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "price",
                        type: "number",
                        value: orderInputs?.price,
                        placeholder: "ex: 52.90",
                        onChange: (e) =>
                          handleOrderInputsChange("price", e.target.value),
                      }}
                    />
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect
                        value={orderInputs?.purchaseDate}
                        isValidDate={valid}
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        inputProps={{ placeholder: "Ημερομηνία Αγοράς:" }}
                        onChange={(e) =>
                          handleOrderInputsChange("purchaseDate", e)
                        }
                      />
                    </FormControl>

                    <div
                      style={{ marginTop: 30 }}
                      className={classes.textCenter}
                    >
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          getApiData("v1/products", "products", "POST", {
                            categoryID: orderInputs.productGroup,
                            price: parseFloat(orderInputs.price),
                            salesdate: formatISO(
                              new Date(orderInputs.purchaseDate)
                            ),
                          }).then((r) => {
                            if (!Array.isArray(r)) {
                              snackNotify(
                                404,
                                "Δεν βρέθηκε κάτι με τα κριτήρια που βάλατε"
                              );
                              setSnackOpen(true);
                            } else {
                              handleNext();
                            }
                          });
                        }}
                        round
                        color="blackColor"
                      >
                        Δείτε τα προγράμματα
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
