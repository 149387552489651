import {
  blackColor,
  cardTitle,
  container,
  hexToRgb,
  mlAuto,
  mrAuto,
  samsungLight,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const pricingStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    // paddingTop: "5vh",
    borderRadius: "6px",
    backgroundColor: whiteColor,
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    marginBottom: "50px",
    padding: "40px 0px",
  },
  mrAuto,
  mlAuto,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
    "& small": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0.8)!important",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  pricingSection: {
    padding: "80px 0px",
  },
  textInfo: {
    color: samsungLight + " !important",
  },
  cardSignup: {
    borderRadius: "6px",

    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    marginBottom: "50px",
    padding: "40px 0px",
  },
  btn: {
    display: "inline-block",
    position: "relative",
    text: "decoration: none",
    // color: "#f9a9ae",
    width: "100%",
    // height: "50px",
    lineHeight: "50px",
    borderRadius: "7px",
    textAlign: "center",
    verticalAlign: "middle",
    overflow: "hidden",
    fontWeight: "bold",
    marginBottom: 20,

    // backgroundImage: ,
    // backgroundImage: "linear-gradient(#fed6e3 0%, #ffaaaa 100%)",
    // textShadow: "1px 1px 1px rgba(255, 255, 255, 0.66)",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.28)",
  },
};

export default pricingStyle;
