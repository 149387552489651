/*!

=========================================================
* Samsung whitelabel Allianz
=========================================================

* Coded by Progressnet

=========================================================

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import PresentationPage from "./views/PresentationPage/PresentationPage.js";
import { AppProvider } from "./config/AppProvider";

ReactDOM.render(
  <AppProvider>
    <Router>
      <PresentationPage />
    </Router>
  </AppProvider>,
  document.getElementById("root")
);
