/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { findUnique } from "../../config/helpers";

import pricingStyle from "../../assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle";
import {
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useAppContext } from "../../config/AppProvider";
import { secondaryColor } from "../../assets/jss/material-kit-pro-react";
import Small from "../../components/Typography/Small";

const useStyles = makeStyles(pricingStyle);

export default function FindProgram2({ handleNext, handleBack }) {
  const { data, setCart } = useAppContext();
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [selectedIndex2, setSelectedIndex2] = React.useState(null);
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState("");
  const [ipid, setIpid] = useState("");
  const [addOnProducts, setAddonProducts] = useState([]);

  useEffect(() => {
    if (data?.products?.length) {
      setAddonProducts(findUnique(data?.products, "addOnProduct"));
    }
  }, [data?.products]);

  useEffect(() => {
    if (!selectedIndex && !selectedIndex2) {
      setPrice(null);
    }
    if (selectedIndex2) {
      const sel = data?.products?.find((el) => el.mainCover === selectedIndex2);
      setPrice(sel?.grossPrice);
      setDescription(sel?.insuranceDescription);
      setIpid(sel?.ipidUrl);
    } else if (selectedIndex) {
      const sel = data?.products?.find((el) => el.mainCover === selectedIndex);
      setAddonProducts(
        data?.products?.filter(
          (el) => el.insuranceProduct === sel.insuranceProduct
        )
      );
      if (!sel.addOnProduct) {
        setPrice(sel?.grossPrice);
        setIpid(sel?.ipidUrl);
        setDescription(sel?.insuranceDescription);
      }
    }
  }, [selectedIndex, selectedIndex2]);

  const handleIndexClick = (event, index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      setSelectedIndex2(null);
    } else {
      setSelectedIndex(null);
      setSelectedIndex2(null);
    }
  };
  const handleIndex2Click = (event, index) => {
    if (selectedIndex2 !== index) {
      setSelectedIndex2(index);
    } else {
      setSelectedIndex2(null);
    }
  };

  const classes = useStyles();

  if (!data?.products) {
    return <LinearProgress />;
  }
  const handleNextPage = () => {
    if (selectedIndex2) {
      const sel = data?.products?.find((el) => el.mainCover === selectedIndex2);
      setCart(sel);
    } else {
      const sel = data?.products?.find((el) => el.mainCover === selectedIndex);
      setCart(sel);
      setSelectedIndex2(null);
    }
    handleNext();
  };

  return (
    <div
      style={{ justifyContent: "center", alignItems: "center" }}
      className={classes.container}
    >
      <GridContainer justify="center">
        <GridItem md={4} sm={4}>
          <Card plain pricing className={classes.cardSignup}>
            <CardBody pricing>
              <p style={{ fontWeight: "bold" }}>Επιλεξτε</p>
              <h5
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                ΒΑΣΙΚΟ ΠΡΟΓΡΑΜΜΑ
              </h5>
              <List component="nav" aria-label="main mailbox folders">
                {findUnique(data?.products, "insuranceProduct")?.map((el) => {
                  const nullable = data?.products?.find((elem) => {
                    if (
                      elem.insuranceProduct === el.insuranceProduct &&
                      elem.addOnProduct === null
                    ) {
                      return elem;
                    }
                  });
                  if (nullable) {
                    return (
                      <div key={nullable?.mainCover}>
                        <ListItem
                          // className={classes.btn}
                          button
                          selected={selectedIndex === nullable?.mainCover}
                          style={{
                            backgroundColor:
                              selectedIndex === nullable?.mainCover &&
                              secondaryColor,
                            borderRadius: 2,
                          }}
                          onClick={(event) =>
                            handleIndexClick(event, nullable?.mainCover)
                          }
                        >
                          <ListItemText
                            style={{
                              color:
                                selectedIndex === nullable?.mainCover && "#fff",
                            }}
                            primary={nullable?.insuranceProduct}
                          />
                        </ListItem>
                        {/*<Divider style={{ marginTop: 10, marginBottom: 10 }} />*/}
                      </div>
                    );
                  } else {
                    return (
                      <div key={el?.mainCover}>
                        <ListItem
                          // className={classes.btn}
                          button
                          selected={selectedIndex === el?.mainCover}
                          style={{
                            backgroundColor:
                              selectedIndex === el?.mainCover && secondaryColor,
                            borderRadius: 2,
                          }}
                          onClick={(event) =>
                            handleIndexClick(event, el?.mainCover)
                          }
                        >
                          <ListItemText
                            style={{
                              color: selectedIndex === el?.mainCover && "#fff",
                            }}
                            primary={el?.insuranceProduct}
                          />
                        </ListItem>
                        {/*<Divider style={{ marginTop: 10, marginBottom: 10 }} />*/}
                      </div>
                    );
                  }
                })}
              </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing className={classes.cardSignup}>
            <CardBody pricing>
              <p style={{ fontWeight: "bold" }}>Επιλεξτε</p>
              <h5
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                ΣΥΝΔΥΑΣΤΙΚΕΣ ΠΡΟΣΘΕΤΕΣ ΚΑΛΥΨΕΙΣ
              </h5>

              {addOnProducts.length > 1 ? (
                <List component="nav" aria-label="main mailbox folders">
                  {addOnProducts?.map((el, i) => {
                    // remove 1st empty button
                    if (i > 0) {
                      return (
                        <div key={el?.mainCover}>
                          <ListItem
                            // className={classes.btn}
                            disabled={!selectedIndex}
                            button
                            style={{
                              backgroundColor:
                                selectedIndex2 === el?.mainCover &&
                                secondaryColor,
                              borderRadius: 2,
                            }}
                            selected={selectedIndex2 === el?.mainCover}
                            onClick={(event) => {
                              handleIndex2Click(event, el?.mainCover);
                            }}
                          >
                            <ListItemText
                              style={{
                                color:
                                  selectedIndex2 === el?.mainCover && "#fff",
                              }}
                              primary={el?.addOnProduct}
                            />
                          </ListItem>
                          {/*<Divider style={{ marginTop: 10, marginBottom: 10 }} />*/}
                        </div>
                      );
                    }
                  })}
                </List>
              ) : (
                // remove empty button
                <div></div>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing className={classes.cardSignup}>
            <CardBody pricing>
              <h5
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                ΤΙΜΗ
              </h5>
              <h1 className={classNames(classes.cardTitle, classes.textCenter)}>
                {/*{data?.products[selectedIndex]?.grossPrice*/}
                {/*  ? data?.products[selectedIndex]?.grossPrice*/}
                {/*  : ""}*/}
                {price}
                <small>€</small>
              </h1>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem sm={12}>
          <Card plain pricing>
            <CardBody pricing>
              <h5
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                ΣΥΝΤΟΜΗ ΠΕΡΙΓΡΑΦΗ ΠΡΟΓΡΑΜΜΑΤΟΣ
              </h5>

              <p style={{ paddingLeft: 20, paddingRight: 20 }}>{description}</p>
              {ipid?.length > 0 && (
                <p>
                  Δείτε{" "}
                  <a href={ipid} target="_blank">
                    εδώ
                  </a>{" "}
                  το Προ–συμβατικό Έγγραφο πληροφοριών
                </p>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <div
          style={{
            width: "100%",
            marginTop: 30,
            display: "flex",
            justifyContent: "space-around",
          }}
          className={classes.textCenter}
        >
          <Button onClick={handleBack} round color="blackColor">
            Πίσω
          </Button>
          <Button
            disabled={selectedIndex === null}
            onClick={handleNextPage}
            round
            color="blackColor"
          >
            Συνέχεια
          </Button>
        </div>
      </GridContainer>
    </div>
  );
}
