import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import FindProgram1 from "../../views/FindProgram/FindProgram1";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import FindProgram2 from "../../views/FindProgram/FindProgram2";
import FindProgram3 from "../../views/FindProgram/FindProgram3";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import Button from "../../components/CustomButtons/Button.js";
import CardBody from "../Card/CardBody";
import { useAppContext } from "../../config/AppProvider";

const useStyles = makeStyles(signupPageStyle);

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

function getStepContent(stepIndex, handleNext, handleBack) {
  switch (stepIndex) {
    case 0:
      return <FindProgram1 handleNext={handleNext} />;
    case 1:
      return <FindProgram2 handleNext={handleNext} handleBack={handleBack} />;
    case 2:
      return <FindProgram3 handleNext={handleNext} handleBack={handleBack} />;
    default:
      return "Something went very Wrong :( ";
  }
}

export default function Steps() {
  const { alpha } = useAppContext();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Stepper
        // className={classes.cardSignup}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <Card className={classes.cardSignup}>
                    <CardBody
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography>
                        Πατώντας &quot;Πληρωμή&quot; θα μεταφερθείτε στο ασφαλές
                        περιβάλλον της Alpha Bank όπου θα ολοκληρώσετε την
                        πληρωμή δίνοντας τα στοιχεία της πιστωτικής σας κάρτας
                      </Typography>
                    </CardBody>
                    <div
                      style={{
                        width: "100%",
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className={classes.textCenter}
                    >
                      <Button simple onClick={handleReset} round color="gray">
                        Αρχή
                      </Button>
                      <a
                        href={alpha?.paymenturl}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Button round color="blackColor">
                          Πληρωμή
                        </Button>
                      </a>
                    </div>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        ) : (
          <div>{getStepContent(activeStep, handleNext, handleBack)}</div>
        )}
      </div>
    </div>
  );
}
