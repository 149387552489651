import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import componentsStyle from "../../../assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

import falling from "../../../assets/img/samsung/falling.webp";
// import phone from "../../../assets/img/samsung/phone.png";
// import tablet from "../../../assets/img/samsung/tablet.png";
// import tv from "../../../assets/img/samsung/tv.png";
// import vaccum from "../../../assets/img/samsung/vaccum.png";
// import washing from "../../../assets/img/samsung/washing.png";
// import watch from "../../../assets/img/samsung/watch.png";

const useStyles = makeStyles(componentsStyle);

export default function SectionComponents() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card plain blog className={classes.card4}>
              <CardHeader image plain>
                <a href="#pablito" onClick={(e) => e.preventDefault()}>
                  <img src={falling} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${falling})`, opacity: "1" }}
                />
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem
            className={classes.mlAuto}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: 20,
            }}
            md={6}
            lg={6}
            sm={12}
            xs={12}
          >
            <h2 className={classes.title}>Samsung Protect</h2>

            <h5 className={classes.description}>
              Συνεχίζουμε να είμαστε στο πλευρό σας, για όλες τις συσκευές
              Samsung! Με την αγορά ασφάλειας συσκευών, σας δίνεται η δυνατότητα
              να επεκτείνετε τη χρονική διάρκεια ισχύος της εγγύησης
              κατασκευαστή της συσκευής σας για να αντιμετωπίζετε τυχόν βλάβες
              που εμπίπτουν σε αυτή, με ασφάλεια και πάντα με εξυπηρέτηση από τα
              εξουσιοδοτημένα Κέντρα Τεχνικής Υποστήριξης της Samsung.
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
