import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-pro-react/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const { title, description, icon, width, side, className } = props;
  const classes = useStyles();
  // const iconWrapper = classNames({
  //   [classes.iconWrapper]: true,
  //   [classes[iconColor]]: true,
  //   [classes.iconWrapperVertical]: vertical,
  // });

  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [classes.infoAreaSide]: side,
    [className]: className !== undefined,
  });

  return (
    <div className={infoAreaClasses}>
      <img style={{ width }} src={icon} alt={title} />
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
