import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";

import styles from "../../assets/jss/material-kit-pro-react/components/headerStyle.js";
import MemoPartnerLogo from "../../components/svg/PartnerLogo";
// import allianzLogo from "../../assets/img/Allianz_Partners_CMYK_C.png";
import { Divider } from "@material-ui/core";
import CustomizedSnackbars from "../Snackbar/Snack";
import MemoAllianzLogo from "../svg/AllianzLogo";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const { color, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <AppBar className={appBarClasses}>
      <CustomizedSnackbars />
      <Toolbar className={classes.container}>
        <Button
          disabled
          // style={{ color: scrollTopColor === "scrolled" ? "black" : "white" }}
          className={classes.title}
        >
          <MemoPartnerLogo size={130} />
        </Button>
        <Divider
          style={{
            marginLeft: 12,
            marginRight: 12,
            height: 40,
            backgroundColor: "#000",
          }}
          orientation="vertical"
        />

        <Button
          disabled
          // style={{ color: scrollTopColor === "scrolled" ? "black" : "white" }}
          className={classes.title}
        >
          <MemoAllianzLogo size={151.49} />
        </Button>
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "gray",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "gray",
      "dark",
    ]).isRequired,
  }),
};
