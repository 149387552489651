import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import FindProgram from "../../../views/FindProgram/FindProgram.js";
import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";
import Steps from "../../../components/Steps/Steps.js";

// import cardsTest from "assets/img/assets-for-demo/cards-test.png";

const useStyles = makeStyles(cardsStyle);

export default function SectionCards() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <Steps />
      </div>
    </div>
  );
}
